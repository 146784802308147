import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Divider, IconButton, Link as MuiLink } from '@mui/material';

import ActivityWidget from '../components/ActivityWidget';
import Alert from '../components/Alert';
import AlertButton from '../components/AlertButton';
import Dialog from '../components/Dialog/index';
import RichTextEditor from '../components/RichText/Editor';
import RichTextView from '../components/RichText/View';
import UploadButton from '../components/UploadButton';
import YoutubePlayer from '../components/YoutubePlayer';
import QuizIcon from '../icons/QuizIcon';
import routes from '../pages/routes';
import { PipContainer, PipContainerMode, PipHeader } from '../pip-container';
import AlertPlayground from './Alert';
import ButtonPlayground from './Button';
import ExpandableListItemPlayground from './ExpandableListItemPlayground';
import ExpandableListPlayground from './ExpandableListPlayground';
import FabMenuPlayground from './FabMenuPlayground';
import FormControlsPlayground from './FormControls';
import NotificationPlayground from './NotificationPlayground';
import PipContainerPlayground, { usePlaygroundPipContainer } from './PipContainer';
import ProgressbarPlayground from './ProgressbarPlayground';
import sampleRichTexts from './sample-richtext';
import SectionPlayground from './Section';
import TagPlayground from './TagPlayground';
import TimerPlayground from './Timer';
import TooltipPlayground from './Tooltip';
import TypographyPlayground from './TypographyPlayground';
import UserAvatarPlayground from './UserAvatarPlayground';
import UserListPlayground from './UserListPlayground';
import VideoJSPlayground from './VideoJSPlayground';

const richTextSamples = [
  `Some announcement text<br><formula format="tex" display="block" id="622700b644ce3b6bd324d24c" ach="80" acw="250" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/16832474-decf-4757-a107-46e94512ac3d-xxl.png">e=mc^2</formula>`,
  `<formula format="tex" display="block" id="62daf21f484d6225682a2bb3" ach="190" acw="555" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/a628994c-ae05-4f1d-a2bf-48e3c2757d2d-xxl.png">\\binom{n}{k} = \\frac{n!}{k!(n-k)!}</formula><br>True or false&nbsp;<formula format="tex" display="inline" id="5ed496519973ed274ee0fe16" ach="90" acw="200" url="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/math/4ec68d8e-0d66-41d8-a0ef-ecad37e6de98-xxl.png">y=x^2</formula>&nbsp;<br><br><img src="https://acadly-test.s3.amazonaws.com/c/5eb9332a76885812b7af6d3e/i/3520a627-5488-480d-ad20-7e80940372b3.png" acw="350" ach="110">`,
];

const Playground = () => {
  const location = useLocation();
  const {
    id: pipId,
    isOpen: isPipOpen,
    ref: pipRef,
    open: openPip,
    close: closePip,
    changeMode: requestPipModeChange,
  } = usePlaygroundPipContainer();

  useEffect(
    function changePipMode() {
      if (location.pathname === routes.playground.path) {
        requestPipModeChange(PipContainerMode.PIP);
      } else if (location.pathname === routes.pipContainerPlayground.path) {
        requestPipModeChange(PipContainerMode.EMBEDDED);
      }
    },
    [location.pathname, openPip, requestPipModeChange]
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen((isOpen) => !isOpen);

  const handleConfirmAlertCancel = () => {
    console.log('confirm alert cancelled');
  };

  const handleConfirm = async () => {
    console.log('confirmed!');
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  return (
    <>
      {isPipOpen && (
        <PipContainer
          id={pipId}
          ref={pipRef}
          aspectRatio={16 / 9}
          footerHeight={60}
          minWidth={300}
          minHeight={200}
          embedTargetSelector="#pip-container-playground-target"
        >
          <PipHeader>
            <IconButton sx={{ ml: 'auto' }} onClick={closePip}>
              <CloseIcon />
            </IconButton>
          </PipHeader>
          <div style={{ backgroundColor: '#fff' }}>
            Pip content
            <br />
            <Button onClick={() => pipRef.current?.requestModeChange(PipContainerMode.PIP)}>
              Request pip mode
            </Button>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quaerat commodi voluptatem,
              asperiores reiciendis harum perspiciatis porro possimus quidem, fugiat tempore quisquam velit
              dolore rerum. Culpa inventore perferendis neque dolorum!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quaerat commodi voluptatem,
              asperiores reiciendis harum perspiciatis porro possimus quidem, fugiat tempore quisquam velit
              dolore rerum. Culpa inventore perferendis neque dolorum!
            </p>
          </div>
        </PipContainer>
      )}
      <Routes>
        <Route path={routes.formControlsPlayground.relativePath} element={<FormControlsPlayground />} />
        <Route path={routes.pipContainerPlayground.relativePath} element={<PipContainerPlayground />} />
        <Route
          path={routes.richTextPlayground.relativePath}
          element={
            <>
              <RichTextEditor
                initialValue={sampleRichTexts[4]}
                placeholder="Type something..."
                onChange={(value) => console.log(value)}
                toolbar={{
                  uploadAttachmentConfig: {
                    url: '/geturl/quizzes/files/upload',
                    data: {
                      activityId: '62e91c48484d6225682a2d65',
                      activityType: 'quizzes',
                    },
                  },
                }}
              />
              <RichTextView richtext={richTextSamples[1]} />
            </>
          }
        />
        <Route path={routes.timerPlayground.relativePath} element={<TimerPlayground />} />
        <Route path={routes.tooltipPlayground.relativePath} element={<TooltipPlayground />} />
        <Route path={routes.progressbarPlayground.relativePath} element={<ProgressbarPlayground />} />
        <Route path={routes.buttonPlayground.relativePath} element={<ButtonPlayground />} />
        <Route path={routes.tagPlayground.relativePath} element={<TagPlayground />} />
        <Route path={routes.userAvatarPlayground.relativePath} element={<UserAvatarPlayground />} />
        <Route path={routes.sectionPlayground.relativePath} element={<SectionPlayground />} />
        <Route path={routes.alertPlayground.relativePath} element={<AlertPlayground />} />
        <Route
          path="*"
          element={
            <div>
              <Button component={Link} to={routes.tooltipPlayground.path}>
                Tooltip Playground
              </Button>
              {isPipOpen ? (
                <Button component={Link} to={routes.pipContainerPlayground.path}>
                  Go to PipContainer playground
                </Button>
              ) : (
                <Button onClick={() => openPip({})}>Open Pip</Button>
              )}
              <Button LinkComponent={MuiLink} href={routes.buttonPlayground.path}>
                Button Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.formControlsPlayground.path}>
                Form controls Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.progressbarPlayground.path}>
                Progressbar Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.tagPlayground.path}>
                Tag Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.userAvatarPlayground.path}>
                UserAvatar Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.sectionPlayground.path}>
                Section Playground
              </Button>
              <Button LinkComponent={MuiLink} href={routes.alertPlayground.path}>
                Alert Playground
              </Button>
              <NotificationPlayground />
              <div style={{ position: 'relative', height: 500, width: '100%' }}>
                <FabMenuPlayground />
              </div>
              <UploadButton
                accept="*"
                onUpload={console.log}
                onUploadError={console.log}
                requestConfig={{
                  url: '/geturl/assignments/files/upload',
                  headers: {
                    courseId: '5eb9332a76885812b7af6d3e',
                  },
                  data: {
                    activityId: '623ab1b042d91e23c9fed7b8',
                    activityType: 'assignments',
                  },
                }}
              />

              <AlertButton>
                Confirm Button
                <Alert>
                  <Alert.Header>Confirmation</Alert.Header>
                  <Alert.Body>Are you sure?</Alert.Body>
                  <Alert.Footer>
                    <Alert.Action variant="text" onClick={handleConfirmAlertCancel}>
                      No
                    </Alert.Action>
                    <Alert.Action onClick={handleConfirm}>Yes</Alert.Action>
                  </Alert.Footer>
                </Alert>
              </AlertButton>
              <Button onClick={toggleDialog}>Open Dialog</Button>
              <Dialog open={isDialogOpen} header="Test" footer={<Button>Next</Button>} onClose={toggleDialog}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro placeat inventore ipsam. Velit
                veritatis soluta eligendi. Aspernatur maiores magni nobis fugiat ipsam similique, molestias
                labore ab optio, voluptates, magnam corrupti? Lorem ipsum dolor, sit amet consectetur
                adipisicing elit. Ut debitis illo at rem suscipit nulla fuga quam temporibus perferendis
                deserunt iure, voluptatum quae iste, nam blanditiis optio rerum numquam cumque. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Odio molestias blanditiis libero ipsa adipisci
                totam dolor dolores corporis magni molestiae. Accusantium, doloribus tempore reprehenderit
                minima vitae quia. Harum, error quo. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Porro placeat inventore ipsam. Velit veritatis soluta eligendi. Aspernatur maiores magni nobis
                fugiat ipsam similique, molestias labore ab optio, voluptates, magnam corrupti? Lorem ipsum
                dolor, sit amet consectetur adipisicing elit. Ut debitis illo at rem suscipit nulla fuga quam
                temporibus perferendis deserunt iure, voluptatum quae iste, nam blanditiis optio rerum numquam
                cumque. Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio molestias blanditiis
                libero ipsa adipisci totam dolor dolores corporis magni molestiae. Accusantium, doloribus
                tempore reprehenderit minima vitae quia. Harum, error quo. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Porro placeat inventore ipsam. Velit veritatis soluta eligendi.
                Aspernatur maiores magni nobis fugiat ipsam similique, molestias labore ab optio, voluptates,
                magnam corrupti? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut debitis illo at
                rem suscipit nulla fuga quam temporibus perferendis deserunt iure, voluptatum quae iste, nam
                blanditiis optio rerum numquam cumque. Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Odio molestias blanditiis libero ipsa adipisci totam dolor dolores corporis magni
                molestiae. Accusantium, doloribus tempore reprehenderit minima vitae quia. Harum, error quo.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro placeat inventore ipsam. Velit
                veritatis soluta eligendi. Aspernatur maiores magni nobis fugiat ipsam similique, molestias
                labore ab optio, voluptates, magnam corrupti? Lorem ipsum dolor, sit amet consectetur
                adipisicing elit. Ut debitis illo at rem suscipit nulla fuga quam temporibus perferendis
                deserunt iure, voluptatum quae iste, nam blanditiis optio rerum numquam cumque. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Odio molestias blanditiis libero ipsa adipisci
                totam dolor dolores corporis magni molestiae. Accusantium, doloribus tempore reprehenderit
                minima vitae quia. Harum, error quo.
              </Dialog>
              <div style={{ maxWidth: 490, margin: '30px auto' }}>
                <Divider sx={{ margin: '2rem 0' }} />
                <UserListPlayground />
                <Divider sx={{ margin: '2rem 0' }} />
                <ExpandableListPlayground />
                <Divider sx={{ margin: '2rem 0' }} />
                <ExpandableListItemPlayground />
                <Divider sx={{ margin: '2rem 0' }} />
                <ActivityWidget
                  icon={<QuizIcon style={{ color: 'red' }} />}
                  title="Quiz 08"
                  tags={{
                    urgent: true,
                    edited: true,
                  }}
                  unseenComments={20}
                  headerMeta="Submitted: 20"
                  description="Test quiz"
                />
                <YoutubePlayer videoId="nDeaiqp1cu4" />
                <VideoJSPlayground />
                <TypographyPlayground />
              </div>
            </div>
          }
        />
      </Routes>
    </>
  );
};

export default Playground;
